@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Bold";
  src: local("Teko-Bold"),
  url('./assets//fonts/Teko-Bold.ttf')
}

@font-face {
  font-family: "SemiBold";
  src: local("Teko-SemiBold"),
  url('./assets//fonts/Teko-SemiBold.ttf')
}

@font-face {
  font-family: "Medium";
  src: local("Teko-Medium"),
  url('./assets//fonts/Teko-Medium.ttf')
}



body {
  background-color: white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}